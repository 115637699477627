.table {
	margin-top: 20px;
	overflow: scroll;
	color: #6a5d5d;
	background-color: white;
	height: 400px;
}

.table tr {
	display: flex;
	justify-content: space-between;
}

.table td {
	padding: 0.5rem;
	border: none;
}

.table tr:nth-of-type(odd) {
	background-color: #f3f2f8;
}
